// Work.jsx
import React, { useState, useEffect, useMemo } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Spinner,
  Alert,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { urlFor, client } from "../../client";

import "./Work.scss";
import "../../index.scss";

const FILTER_BUTTONS = ["Apps", "UI/UX", "Web App", "React JS", "All"];

const FilterButton = ({ isActive, label, onClick }) => (
  <Button
    variant={isActive ? "outline-primary" : "outline-secondary"}
    onClick={onClick}
    className={`filter-button ${isActive ? "active" : ""}`}
  >
    {label}
  </Button>
);

const useFilteredWorks = () => {
  const [works, setWorks] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState({ hasError: false, message: "" });
  const [activeFilter, setActiveFilter] = useState("All");
  const [modalShow, setModalShow] = useState(false);
  const [currentWork, setCurrentWork] = useState(null);
  const [expandedWorkIds, setExpandedWorkIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "works"]');
        setWorks(result);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleExpandWork = (workId, event) => {
    event.stopPropagation();
    if (expandedWorkIds.includes(workId)) {
      setExpandedWorkIds((prevIds) => prevIds.filter((id) => id !== workId));
    } else {
      setExpandedWorkIds((prevIds) => [...prevIds, workId]);
    }
  };

  const filteredWorks = useMemo(() => {
    return activeFilter === "All"
      ? works
      : works.filter((work) => work.tags.includes(activeFilter));
  }, [works, activeFilter]);

  return {
    works: filteredWorks,
    setFilter: setActiveFilter,
    activeFilter,
    isLoading,
    error,
    modalShow,
    setModalShow,
    currentWork,
    setCurrentWork,
    toggleExpandWork,
    expandedWorkIds,
  };
};

const Work = () => {
  const {
    works,
    setFilter,
    activeFilter,
    isLoading,
    error,
    modalShow,
    setModalShow,
    currentWork,
    setCurrentWork,
    expandedWorkIds,
    toggleExpandWork,
    retryFetch,
  } = useFilteredWorks();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleSelect = (eventKey) => {
    setFilter(eventKey);
  };

  return (
    <Container fluid className="portfolio">
      <h2 className="text-title text-center my-5 pt-5">
        My Creative <span>Portfolio</span>
      </h2>

      <Row className="justify-content-center filter-section mb-4">
        {windowWidth <= 605 ? (
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle
              className="filter-dropdown-toggle"
              id="dropdown-basic"
            >
              {activeFilter}
            </Dropdown.Toggle>
            <Dropdown.Menu className="filter-dropdown-menu">
              {FILTER_BUTTONS.map((filterName) => (
                <Dropdown.Item
                  className="filter-dropdown-item"
                  eventKey={filterName}
                  key={filterName}
                  active={activeFilter === filterName}
                >
                  {filterName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <motion.div whileTap={{ scale: 0.95 }}>
            {FILTER_BUTTONS.map((item) => (
              <FilterButton
                isActive={item === activeFilter}
                label={item}
                onClick={() => setFilter(item)}
                key={item}
              />
            ))}
          </motion.div>
        )}
      </Row>

      {isLoading && (
        <div className="text-center my-5">
          <Spinner animation="border" />
        </div>
      )}
      {error.hasError && (
        <Alert variant="danger" className="my-4">
          Error loading works: {error.message}
          <Button onClick={retryFetch}>Retry</Button>
        </Alert>
      )}

      <Row className="work-section">
        {works.length > 0 ? (
          works.map((work) => (
            <Col xl={3} lg={4} md={4} sm={6} xs={12} key={work._id}>
              <motion.div
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
              >
                <Card
                  className="work-card mb-4"
                  onClick={() => {
                    setCurrentWork(work);
                    setModalShow(true);
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={urlFor(work.imgUrl)}
                    loading="lazy"
                    className="card-image"
                  />
                  <Card.Body>
                    <Card.Title>{work.title}</Card.Title>
                    <Card.Text>
                      {expandedWorkIds.includes(work._id) ||
                      work.description.length <= 15
                        ? work.description
                        : work.description.substring(0, 15) + "... "}
                      {work.description.length > 15 && (
                        <span
                          role="button"
                          onClick={(e) => toggleExpandWork(work._id, e)}
                          className="text-primary read-more"
                        >
                          {expandedWorkIds.includes(work._id)
                            ? "Read less"
                            : "Read more"}
                        </span>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          ))
        ) : (
          <Col md={12} className="my-4">
            <Alert variant="info" className="text-center">
              Nothing available for this category.
            </Alert>
          </Col>
        )}
      </Row>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {currentWork?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={urlFor(currentWork?.imgUrl)}
            alt={currentWork?.title}
            style={{ width: "100%", marginBottom: "15px" }}
          />
          <p>{currentWork?.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <a
            className="custom-button"
            href={currentWork?.projectLink}
            target="_blank"
            rel="noreferrer"
          >
            View Project <AiFillEye className="icon" />
          </a>
          <a
            className="custom-button"
            href={currentWork?.codeLink}
            target="_blank"
            rel="noreferrer"
          >
            View Code <AiFillGithub className="icon" />
          </a>
          <button className="custom-button" onClick={() => setModalShow(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Work;
