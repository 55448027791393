import React from "react";
import { Navbar as BSNavbar, Nav, Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { images } from "../../constants";
import { useTheme } from "../../wrapper/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import "./Navbar.scss";

const NAV_LINKS = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Skills", path: "/skills" },
  { name: "Work", path: "/work" },
  { name: "Contact", path: "/contact" },
];

const ThemeSwitch = () => {
  const { theme, toggleTheme } = useTheme();
  const isDark = theme === "dark";

  return (
    <label className="theme-switch">
      <input type="checkbox" checked={isDark} onChange={toggleTheme} />
      {isDark ? (
        <FontAwesomeIcon icon={faSun} className="theme-icon" />
      ) : (
        <FontAwesomeIcon icon={faMoon} className="theme-icon" />
      )}
    </label>
  );
};

const Navbar = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        document.body.classList.add("user-is-scrolling");
      } else {
        document.body.classList.remove("user-is-scrolling");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <BSNavbar bg="transparent" variant={theme} fixed="top" expand="lg">
      <Container fluid>
        <BSNavbar.Brand onClick={() => navigate("/")}>
          <img src={images.logo} alt="Logo" className="navbar-logo" />
        </BSNavbar.Brand>
        <BSNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BSNavbar.Collapse id="responsive-navbar-nav">
          <div className="nav-links-container">
            <Nav className="ml-auto">
              {NAV_LINKS.map((linkItem) => (
                <Nav.Link
                  key={linkItem.name}
                  onClick={() => navigate(linkItem.path)}
                  active={location.pathname === linkItem.path}
                >
                  {linkItem.name}
                </Nav.Link>
              ))}
            </Nav>
          </div>
          <div className="theme-switch-wrapper">
            <ThemeSwitch />
          </div>
        </BSNavbar.Collapse>
      </Container>
    </BSNavbar>
  );
};

export default Navbar;
