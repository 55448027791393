import React from "react";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";

const SocialMedia = () => (
  <div>
    <div>
      <a href="https://www.linkedin.com/in/albert-s-4a2660142/">
        <FaLinkedin />
      </a>
    </div>
    <div>
      <a href="https://www.facebook.com/albert.snd/">
        <FaFacebookF />
      </a>
    </div>
    <div>
      <a href="https://www.instagram.com/nosmxkeofficial/">
        <BsInstagram />
      </a>
    </div>
  </div>
);

export default SocialMedia;
