import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNode,
  faHtml5,
  faCss3,
  faReact,
  faJsSquare,
  faGitAlt,
} from "@fortawesome/free-brands-svg-icons";
import { motion } from "framer-motion";
import "./Cube.scss";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const IconFaces = [
  { icon: faNode, color: "#3C873A" },
  { icon: faHtml5, color: "#F06529" },
  { icon: faCss3, color: "#28A4D9" },
  { icon: faReact, color: "#5ED4F4" },
  { icon: faJsSquare, color: "#EFD81D" },
  { icon: faGitAlt, color: "#EC4D28" },
];

const CubeSpinner = () => (
  <div className="cubespinner">
    {IconFaces.map((face, index) => (
      <div className={`face${index + 1}`} key={index}>
        <FontAwesomeIcon icon={face.icon} color={face.color} />
      </div>
    ))}
  </div>
);

const Cube = () => {
  return (
    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="cube-wrapper"
    >
      <CubeSpinner />
    </motion.div>
  );
};

export default Cube;
