import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import { Container, Row, Card } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { motion } from "framer-motion";
import TagCloud from "TagCloud";
import { client, urlFor } from "../../client";
import "./Skills.scss";
import "../../index.scss";

const calculateRadius = () => {
  const minWidth = 320;
  const maxWidth = 1920;
  const minRadius = 150;
  const maxRadius = 450;

  const width = window.innerWidth;

  return (
    minRadius +
    ((width - minWidth) * (maxRadius - minRadius)) / (maxWidth - minWidth)
  );
};

const SKILLS_QUERY = '*[_type == "skills"]';

const useSkills = () => {
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const data = await client.fetch(SKILLS_QUERY);
        setSkills(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchSkills();
  }, []);

  return { skills, loading, error };
};

const SkillCard = memo(({ _id, name, icon }) => (
  <motion.div
    className="skill-card"
    whileHover={{ scale: 1.08 }}
    transition={{ duration: 0.3 }}
  >
    <Card.Img
      variant="top"
      src={urlFor(icon)}
      alt={name}
      className="skill-icon"
    />
    <Card.Body>
      <Card.Title className="text-center skill-name">{name}</Card.Title>
    </Card.Body>
  </motion.div>
));

const Skills = () => {
  const { skills, loading, error } = useSkills();
  const tagCloudRef = useRef(null);
  const [radius, setRadius] = useState(calculateRadius());

  const createTagCloudOptions = (radius) => ({
    radius: radius,
    maxSpeed: "fast",
    initSpeed: "normal",
    keep: true,
    hover: true,
  });

  const updateTagCloud = useCallback(() => {
    const texts = skills.map((skill) => skill.name);
    const options = createTagCloudOptions(radius);
    if (tagCloudRef.current) {
      tagCloudRef.current.innerHTML = "";
      TagCloud(".tagcloud", texts, options);
    }
  }, [skills, radius]);

  useEffect(() => {
    if (skills && skills.length > 0) {
      updateTagCloud();
    }
  }, [skills, updateTagCloud]);

  useEffect(() => {
    const handleResize = () => {
      setRadius(calculateRadius());
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container fluid className="skills-container">
      {loading && <p>Loading...</p>}
      {error && <p>Something went wrong! Please try again later.</p>}
      <Row className="hero-section">
        <Fade top duration={500}>
          <>
            <h1 className="text-title">Skills & Expertise</h1>
            <p className="text-body">
              A showcase of my technical and professional abilities.
            </p>
          </>
        </Fade>
      </Row>

      <Row className="skills-section">
        <Fade bottom duration={500}>
          <div className="text-sphere">
            <div className="tagcloud" ref={tagCloudRef}></div>
          </div>
        </Fade>
      </Row>

      <Row className="expertise-section">
        <Fade bottom duration={500}>
          <Row className="skills-row">
            {skills.map((skill) => (
              <SkillCard key={skill._id} {...skill} />
            ))}
          </Row>
        </Fade>
      </Row>

      <Row className="cta-section">
        <Fade bottom duration={500}>
          <h3 className="text-subtitle">Want to know more?</h3>
          <p className="text-body">
            Dive deeper into my{" "}
            <a href="/portfolio" className="text-link">
              portfolio
            </a>{" "}
            to see the projects I've worked on.
          </p>
          <a
            href="/contact"
            className="cta-link text-link"
            role="button"
            aria-label="Contact Me"
          >
            <button className="cta-btn">Contact Me</button>
          </a>
        </Fade>
      </Row>
    </Container>
  );
};

export default Skills;
