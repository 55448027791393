import React, { useState, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSpeechSynthesis } from "react-speech-kit";
import { Fade } from "react-awesome-reveal";
import { images } from "../../constants";
import { client } from "../../client";
import "./Contact.scss";
import "../../index.scss";

// Constants
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
const FormStatus = {
  IDLE: "idle",
  SENDING: "sending",
  SUBMITTED: "submitted",
  ERROR: "error",
};

// Helper Function to Generate Captcha
const generateCaptcha = () =>
  Array.from({ length: 6 }, () =>
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".charAt(
      Math.floor(Math.random() * 62)
    )
  ).join("");

// Info Column Component
const InfoCol = React.memo(({ type, icon, href, label }) => (
  <Col xs={4} sm={4} md={4} lg={3} className="info-col">
    <Fade bottom duration={500}>
      <a href={href} className="info-card-link">
        <div className="info-icon">
          <img src={icon} alt={type} />
        </div>
        <h2>{type.charAt(0).toUpperCase() + type.slice(1)}</h2>
      </a>
    </Fade>
  </Col>
));

// Contact Form Component
const ContactForm = ({
  onChange,
  onSubmit,
  formData,
  formStatus,
  error,
  captcha,
  handleCaptchaChange,
  onRefreshCaptcha,
}) => {
  const { speak } = useSpeechSynthesis();

  const playCaptchaAudio = useCallback(() => {
    const captchaDescription = captcha
      .split("")
      .map((char) =>
        char.toLowerCase() === char.toUpperCase()
          ? char
          : `${char === char.toUpperCase() ? "uppercase" : "lowercase"} ${char}`
      )
      .join(", ");
    speak({ text: captchaDescription });
  }, [captcha, speak]);

  return (
    <Fade bottom duration={500}>
      <form className="contact-form" onSubmit={onSubmit}>
        {["name", "email", "message"].map((field) => (
          <div key={field}>
            <label htmlFor={field} className="form-label">
              Your {field.charAt(0).toUpperCase() + field.slice(1)}
            </label>
            <input
              type={field === "email" ? "email" : "text"}
              id={field}
              placeholder={`Your ${field}`}
              name={field}
              value={formData[field]}
              onChange={onChange}
              className="form-input"
              required
            />
          </div>
        ))}
        <div className="captcha-section">
          <span className="captcha-display">{captcha}</span>
          <div className="captcha-controls">
            <button
              type="button"
              onClick={onRefreshCaptcha}
              className="refresh-captcha"
              aria-label="Refresh CAPTCHA"
            >
              ⟳
            </button>
            <button
              type="button"
              onClick={playCaptchaAudio}
              className="read-captcha"
              aria-label="Read CAPTCHA"
            >
              🔊
            </button>
          </div>
          <input
            type="text"
            id="captcha"
            placeholder="Enter CAPTCHA"
            name="captcha"
            onChange={handleCaptchaChange}
            required
          />
        </div>
        <button
          type="submit"
          className="form-button"
          disabled={formStatus === FormStatus.SENDING}
        >
          {formStatus === FormStatus.SENDING ? "Sending..." : "Send Message"}
        </button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </Fade>
  );
};

// Main Contact Component
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    captcha: generateCaptcha(),
  });
  const [formStatus, setFormStatus] = useState(FormStatus.IDLE);
  const [error, setError] = useState(null);

  const handleChangeInput = useCallback(({ target: { name, value } }) => {
    setError(null);
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleCaptchaChange = useCallback((e) => {
    setFormData((prevData) => ({ ...prevData, captchaInput: e.target.value }));
  }, []);

  const refreshCaptcha = useCallback(() => {
    // Generate a new captcha and update the formData state
    setFormData((prevData) => ({
      ...prevData,
      captcha: generateCaptcha(),
      captchaInput: "",
    }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !emailRegex.test(formData.email) ||
      formData.captchaInput !== formData.captcha
    ) {
      setError("Invalid email address or CAPTCHA.");
      refreshCaptcha();
      return;
    }
    setFormStatus(FormStatus.SENDING);

    const contactData = {
      _type: "contact",
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    try {
      await client.create(contactData);
      setFormStatus(FormStatus.SUBMITTED);
      setFormData({
        name: "",
        email: "",
        message: "",
        captcha: generateCaptcha(),
      });
    } catch (err) {
      console.error("Submission error:", err);
      setError("An error occurred. Please try again later.");
      setFormStatus(FormStatus.ERROR);
    }
  };

  return (
    <Container className="contact-container">
      <Row
        className="justify-content-center"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Col xs={12} md={10} className="text-center contact-header">
          <Fade top duration={500}>
            <h1 className="head-text">Let's Connect!</h1>
          </Fade>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginLeft: 0, marginRight: 0 }}>
        <InfoCol
          type="Email Me!"
          icon={images.email}
          href="mailto:sandu.albert.alexandru@gmail.com"
          label="Email Me!"
        />
        <InfoCol
          type="Call Me!"
          icon={images.mobile}
          href="tel:+40 (742) 172-306"
          label="Call Me!"
        />
      </Row>
      {/* Row for contact form */}
      <Row className="justify-content-center contact-form-section" style={{ marginLeft: 0, marginRight: 0 }}>
        <Col xs={12} md={8} className="text-center">
          {formStatus !== FormStatus.SUBMITTED ? (
            <ContactForm
              formData={formData}
              onChange={handleChangeInput}
              onSubmit={handleSubmit}
              formStatus={formStatus}
              error={error}
              captcha={formData.captcha}
              handleCaptchaChange={handleCaptchaChange}
              onRefreshCaptcha={refreshCaptcha}
            />
          ) : (
            <Fade bottom duration={500}>
              <h3 className="head-text">Thank you for getting in touch!</h3>
            </Fade>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
