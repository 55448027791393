import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import "./About.scss";
import "../../index.scss";

import FullStackImage from "../../assets/About/FullStack.png";
import DatabaseAdminImage from "../../assets/About/DatabaseAdminImage.png";
import UIUXImage from "../../assets/About/UIUXImage.png";
import MobileAppImage from "../../assets/About/MobileAppImage.png";
import APIDeveloperImage from "../../assets/About/APIDeveloperImage.png";
import FrontEnd from "../../assets/About/FrontEnd.png";
import BackEnd from "../../assets/About/BackEnd.png";

const roleData = [
  {
    title: (
      <>
        Full Stack
        <br />
        Developer
      </>
    ),
    description:
      "From design to deployment, I handle every layer of web application development.",
    imgUrl: FullStackImage,
  },
  {
    title: (
      <>
        Frontend
        <br />
        Developer
      </>
    ),
    description:
      "Crafting visually appealing, functional, and responsive web interfaces.",
    imgUrl: FrontEnd,
  },
  {
    title: (
      <>
        Backend
        <br />
        Developer
      </>
    ),
    description:
      "Managing the server-side: creating APIs, handling databases, and ensuring optimal performance.",
    imgUrl: BackEnd,
  },
  {
    title: (
      <>
        UI/UX
        <br />
        Designer
      </>
    ),
    description:
      "Designing intuitive user interfaces and optimizing the overall user experience.",
    imgUrl: UIUXImage,
  },
  {
    title: (
      <>
        Mobile App
        <br />
        Developer
      </>
    ),
    description:
      "Developing intuitive, smooth, and responsive mobile applications.",
    imgUrl: MobileAppImage,
  },
  {
    title: (
      <>
        API
        <br />
        Developer
      </>
    ),
    description:
      "Specializing in creating and integrating robust APIs for seamless data exchange.",
    imgUrl: APIDeveloperImage,
  },
  {
    title: (
      <>
        Database
        <br />
        Administrator
      </>
    ),
    description:
      "Setting up, maintaining, and optimizing databases for optimal performance and security.",
    imgUrl: DatabaseAdminImage,
  },
];

const InfoItem = ({ title, description }) => (
  <Col xs={12} sm={6} className="info-item text-center">
    <h3>{title}</h3>
    <p>{description}</p>
  </Col>
);

const About = () => (
  <Container fluid className="app__about">
    <Row className="hero-section">
      <Col xs={12} className="text-center">
        <Fade top duration={500} triggerOnce>
          <h1 className="text-title">
            Crafting Exceptional Digital Experiences
          </h1>
          <p className="text-body">
            Bridging the gap between ideas and realization across platforms.
          </p>
        </Fade>
      </Col>
    </Row>

    <Row className="expertise-section">
      <Col xs={12}>
        <div className="expertise-grid">
          {roleData.map((role, index) => (
            <div className="card" key={`role-${index}`}>
              <Fade bottom duration={500} delay={roleData.indexOf(role) * 50}>
                <div className="role-image-wrapper">
                  <img
                    src={role.imgUrl}
                    alt={role.title}
                    className="role-image"
                  />
                </div>
                <div className="text-container">
                  <h2 className="role-title">{role.title}</h2>
                  <p>{role.description}</p>
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </Col>
    </Row>

    <Row className="additional-info">
      <InfoItem
        title="Multi-Platform Development"
        description="From mobile apps to desktop software, I bring your vision to any device of your choice."
      />
      <InfoItem
        title="Security & Optimization"
        description="Safeguarding your software and optimizing for the best user experience is always my priority."
      />
    </Row>

    <Row className="cta-section">
      <Col xs={12} className="text-center">
        <Fade bottom duration={500}>
          <h3 className="text-subtitle">Let's Build the Next Big Thing!</h3>
          <p className="text-body">
            Explore{" "}
            <a href="/work" className="text-link">
              my portfolio
            </a>{" "}
            to see what we can accomplish together. Ready to turn your vision
            into a reality?
          </p>
          <a href="/contact" className="cta-link text-link">
            <button className="cta-btn">Get in touch</button>
          </a>
        </Fade>
      </Col>
    </Row>
  </Container>
);

export default About;
