// frontend_react/utils/client.js

import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

// Note: REACT_APP_ is not needed for Next.js apps, use NEXT_PUBLIC_ for client-side variables
export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: "production",
  token: process.env.REACT_APP_SANITY_TOKEN,
  useCdn: false, // Set to false to ensure fresh data is fetched when using the token
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
