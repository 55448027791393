import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SocialMedia } from "../index";
import "./Footer.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  };
  return (
    <footer className="site-footer">
      <Container fluid className="footer-container">
        <Row className="align-items-center justify-content-center">
          <Col xs={12} sm={4} className="text-center footer-about">
            © {currentYear} by Albert Sandu.
          </Col>
          <Col xs={12} sm={4} className="text-center footer-social">
            <SocialMedia />
          </Col>
          <Col xs={12} sm={4} className="text-center footer-top">
            <button
              onClick={scrollToTop}
              className="link-style-button"
              aria-label="Back to top"
            >
              Back To Top
            </button>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
