import "./AnimatedLetters.scss";
import "../../index.scss";
import classNames from "classnames";

const AnimatedLetters = ({ letterClass, strArray, idx = 0 }) => (
  <>
    {strArray.map((char, i) => (
      <span
        key={`${char}-${i}-${idx}`}
        className={classNames(letterClass, `_${i + idx}`)}
      >
        {char === " " ? "\u00A0" : char}
      </span>
    ))}
  </>
);

export default AnimatedLetters;
