// Home.jsx
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { images } from "../../constants";
import { Fade } from "react-awesome-reveal";
import { useTheme } from "./../../wrapper/ThemeContext";
import AnimatedLetters from "../../components/AnimatedLetters/AnimatedLetters";
import Cube from "./Cube";
import "./Home.scss";

const GREETING_ARRAY = ["H", "i", ",", " ", "I", "'", "m"];
const NAME_ARRAY = ["A", "l", "b", "e", "r", "t"];

const useLetterAnimation = () => {
  const [letterClass, setLetterClass] = useState("text-animate");
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);
  return letterClass;
};

const Home = () => {
  const { theme } = useTheme();
  const letterAnimationClass = useLetterAnimation();

  const profileImageSrc =
    theme === "light" ? images.profileDark : images.profileLight;

  return (
    <Container fluid className="home-container">
      <Row className="main-row align-items-center">
        <Col xxl={6} xl={6} md={6} sm={6} className="profile-col">
          <Row xxl={6} xl={6} md={6} sm={6} className="align-items-center">
            <Col xxl={6} xl={6} md={6} sm={6} className="text-center ps-5">
              <Fade direction="down" delay={400}>
                <Image
                  className="profile-image"
                  src={profileImageSrc}
                  alt="Albert's Picture"
                />
              </Fade>
            </Col>
            <Col xxl={6} xl={6} md={6} sm={6}>
              <Fade direction="down" delay={400}>
                <h1 className="name">
                  <AnimatedLetters
                    letterClass={letterAnimationClass}
                    strArray={GREETING_ARRAY}
                    idx={4}
                  />
                </h1>
              </Fade>
              <Fade direction="down" delay={600}>
                <h1 className="name">
                  <AnimatedLetters
                    letterClass={letterAnimationClass}
                    strArray={NAME_ARRAY}
                    idx={6}
                  />
                </h1>
              </Fade>
              <Fade direction="down" delay={800}>
                <Button
                  variant="primary"
                  className="download-btn"
                  href="/Albert_Sandu_CV.pdf"
                  download
                >
                  Download CV
                </Button>
              </Fade>
            </Col>
          </Row>
          <Row className="text-center description-row">
            <Col>
              <Fade direction="down" delay={1000}>
                <p className="description">
                  I'm a passionate software developer specializing in web
                  technologies. I love creating beautiful, efficient, and
                  user-friendly applications.
                </p>
              </Fade>
            </Col>
          </Row>
        </Col>
        <Col xxl={6} xl={6} md={6} sm={6} className="cube-col text-center">
          <Fade direction="down" delay={400}>
            <Cube />
          </Fade>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
